<template>
  <v-app>
    <v-container class="fill-height">
      <v-row justify="center">
        <div class="text-center">
          <h1 class="display-4 error--text "><strong>{{ code }}</strong></h1>
          <h2 class="my-4">{{ message }}</h2>
          <v-btn color="primary" @click="$router.go(-1)">返回</v-btn>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

export default {
  data() {
    return {code: 404, message: '路径没有找到'}
  },
  mounted() {
    if (this.$route.params.code !== undefined) this.code = this.$route.params.code
    if (this.$route.params.message !== undefined) this.message = this.$route.params.message
    document.title = this.code
  },
  methods: {}
}
</script>

<style>

</style>
